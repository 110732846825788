import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      // alias: "*",
      component: () => import("../views/Home.vue"),
    },
    {
      path: '/legal',
      name: 'Legal',
      // alias: "*",
      component: () => import("../views/Legal.vue"),
    },
    {
      path: "/404",
      alias: "*",
      name: "NoRoute",
      component: () => import("@/views/NoRoute.vue"),
    },
    {
      path: "/devtest",
      name: "testview",
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/TestView.vue"),
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      const el = window.location.href.split('#')[1];
      document.getElementById(el).scrollIntoView({ behavior: 'smooth' });
    }
    return {
      el: to.hash,

    }
  }
})




export default router
