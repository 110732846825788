import axios from "axios";
import BASE_URLS from "../../config.js";
const BASE_DEV_URL = BASE_URLS.urls.LOCAL_URL;

const BASE_URL_API = "http://localhost:9001";
const BASE_URL_PROD = "https://mailer.rayyo.io/";

const headers = {
  "Content-Type": "application/json",
};

const baseURL = () => {
  // if (process.env.NODE_ENV === "development") {
  //   (axios.defaults.baseURL = BASE_URL_API), headers;
  // } else {
    (axios.defaults.baseURL = BASE_URL_PROD), headers;
  // }
};

const SERVICE = axios.create(baseURL());
const MAIN_SERVICE = {
  postContactMail: (data) => {
    return SERVICE.post("/public/emails/contact", data);
  },
};

export { BASE_DEV_URL, MAIN_SERVICE };