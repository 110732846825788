import Vue from "vue";
import Vuex from "vuex";
import { BASE_DEV_URL } from "../service/index";
import { MAIN_SERVICE } from "../service/index";

Vue.use(Vuex);

export default new Vuex.Store({

  state: {
    emailResponse: {
      isSuccess: false,
      message: "",
    },
  },

  mutations: {
    FETCH_EMAIL_RESPONSE: (state, payload) => {
      state.emailResponse = payload;
      setTimeout(() => {
        state.emailResponse = {
          isSuccess: false,
          message: "",
        };
      }, 4000);
    },
  },

  actions: {
    async sendContactEmail({ commit }, data) {
      try {
        const response = await MAIN_SERVICE.postContactMail(data);
        const SUCCESS = !response.data.error;
        const MESSAGE_SUCCESS = {
          isSuccess: true,
          message:
            "Tu mensaje ha sido enviado con éxito, pronto nos pondremos en contacto contigo.",
        };
        if (SUCCESS) {
          commit("FETCH_EMAIL_RESPONSE", MESSAGE_SUCCESS);
        }
      } catch (error) {
        console.log(error);
        if (error) {
          const MESSAGE_FAIL = {
            isSuccess: false,
            message:
              "Lo sentimos, hubo un problema para el envío, intenta de nuevo.",
          };
          commit("FETCH_EMAIL_RESPONSE", MESSAGE_FAIL);
        }
      }
    },
  },

});